.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toggle-slide { max-width: 220px !important; min-width: 100px !important;}

.card-aside { width: 230px !important;}
.mandatory { color: red;}

.ag-theme-quartz {
  --ag-grid-size: 5px;
  --ag-list-item-height: 20px;
}

.ag-theme-quartz.compact, .ag-theme-quartz-dark.compact {
  --ag-grid-size: 3px;
  --ag-list-item-height: 20px;
  --ag-font-size: 10px;
}

.ag-theme-quartz .ag-row:nth-child(odd) {
  background-color: #fff; /* Light gray background for odd rows */
}

.ag-theme-quartz .ag-row:nth-child(even) {
  background-color: #f4f4f5; /* White background for even rows */
}

/* Filter input focus styles */
.ag-theme-quartz .ag-filter-wrapper input:focus,
.ag-theme-quartz .ag-floating-filter-input input:focus {
  border-color: #2263b3; /* Change border color to blue on focus */
  box-shadow: 0 0 5px rgba(0, 0, 255, 0.5); /* Add blue shadow on focus */
  outline: none; /* Remove the default outline */
}
/* custom-ag-grid.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.ag-theme-quartz {
  font-family: 'Roboto', sans-serif;
}

/* Optional: Customize specific elements if needed */
.ag-theme-quartz .ag-header-cell-label {
  font-family: 'Roboto', sans-serif;
}

.ag-theme-quartz .ag-cell {
  font-family: 'Roboto', sans-serif;
}

.ag-theme-quartz .ag-header-row {
 background-color: #fff;
}

.ag-theme-quartz .ag-header-cell-label {
  font-family: 'Roboto', sans-serif;
}
.button { cursor: pointer;}

.nk-block-head-lg { padding-bottom: 1em;}

h6 { color: #004293; }

.nk-menu .nk-menu-link, .nk-menu .nk-menu-sub .nk-menu-link, .link-list-menu a, .overline-title-alt { color: #000;}
/* customStyles.css */
.wrap-header .ag-header-cell-label {
  white-space: normal !important; /* Allow wrapping of text */
  line-height: normal; /* Adjust line height for better readability */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
}

/* Optional: Increase the header height if needed */
.ag-header-cell {
  
  align-items: center;
}

.ag-header-cell-text {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}

.wrap-header .rdt_TableHeadRow .rdt_TableCol {
  white-space: normal !important; 
  line-height: 1.5;              
  word-wrap: break-word;         
  min-height: 56px;               
  display: flex;
  align-items: center;            
  justify-content: center;      
}

#customDataItems .data-item {
  padding: 0.5rem 1.25rem
}

.customLabel { color: #000;}
.diagram-component {
  width: 100%;
  height: 600px;
  border: 1px solid #d3d3d3;
}
.cardBox .card-inner-xl{ padding: 1.5rem !important;}
.nk-content-body > .nk-block-head:first-child { padding-bottom: 1rem;}
.nk-content { padding: 15px;}
.steps h5 { font-weight: bold !important;}

.custom-control .custom-checkbox { padding-left: 1.1rem;}

.nk-header.is-light:not([class*=bg-]) {
  background: #fff;
  border-top: 3px solid #2263b3;
  border-bottom: 3px solid #f1f1f1;
}
@media (min-width: 992px) {
  .nk-menu > li.nk-menu-item > .nk-menu-link {
      padding: 1.25rem 0;
      font-size: 16px;
  }
}
.custom-compact-rows .rdt_TableCell {
  padding-top: 3px;
  padding-bottom: 3px;
  
}
.custom-compact-rows .rdt_TableRow {
  min-height: 10px; /* Adjust this value to your preferred height */
}
.rdt_Pagination { border-top: 0;}

.rdt_Pagination { border: 0;}
.custom-alert .alert.alert-icon { padding-left: 1em;}
.page-title {
  font-size: 1.2rem;
}
.custom-compact-row { min-height: 200px;}

.rdt_Table { min-height: 200px;}


.ant-select-arrow{
  display: none !important;
}
.ant-select-selector{
  border: 0.8px solid rgb(219, 223, 234) !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.inline-select{
  display: flex;
  align-items: center;
  justify-content: flex-start;
} 
.inline-select .form-group{
  width: 80%;
  margin-left: 15px;
}
